.logo-container{
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	border-radius: 5px;
	background: #fff;
}
.logo-icon-light{
	width: 20px;
	height: 20px;
	background: url("./logo-light.svg") no-repeat;
	background-size: contain;
}

.logo-icon-dark{
	width: 30px;
	height: 30px;
	background: url("./logo-dark.svg") no-repeat;
	background-size: contain;
}
