@keyframes pulse {
  0% {
    background-color: #36914c;
    color: #ffffff;
  }
  50% {
    background-color: #ffffff;
    color: #212529;
  }
  100% {
    background-color: #36914c;
    color: #ffffff;
  }
}

.highlight-pulse {
  animation: pulse 1s infinite;
}
