.dropdown-prince {
    position: relative;
}
.dropdown-prince__list {
    width: 200px;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    position: absolute;
    z-index: 100;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    transform: translate(-10px, 0px);
    transition: transform 0.1s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.dropdown-prince__list.show {
    visibility: visible;
    transform: translate(-10px, 10px);
}

.dropdown-prince__checked {
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
}

.dropdown-prince__list > div {
    cursor: pointer;
    padding: 5px 10px;
    background: white;
    font-size: 12px;
}

.dropdown-prince__list > div:last-child {
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 10px;
}

.dropdown-prince__list > div:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 10px;
}

.dropdown-prince__list > div:hover {
    background: #f8f9fa;
}

.lds-dual-ring {
    display: inline-block;
    width: 14px;
    height: 14px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 14px;
    height: 14px;
    margin: 0px;
    border-radius: 50%;
    border: 3px solid #000;
    border-color: silver transparent silver transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
