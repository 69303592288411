#purchase-map{
	width: 100%;
}

.purchase-map-buffer{
	overflow-y: scroll;
}

.purchase-map-marker{
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	background: rgb(255, 0, 0);
	color: white;
	cursor: pointer;
	font-size: 0.8rem;
	transform: translate(-15px, -15px);
}

.purchase-map-marker.free.pulse, .purchase-map-marker.in_active_route.pulse{
	animation: pulse-red 2s infinite;
}

.purchase-map-marker.in_route.pulse{
	animation: pulse-black 2s infinite;
}

.purchase-map-marker.free{
	background: red;
}

.purchase-map-marker.in_route{
	background: black;
	color: white;
}

.purchase-map-marker.in_active_route{
	background: black;
	color: white;
	border: 4px solid red;
}

.purchase-map-marker.small{
	width: 10px;
	height: 10px;
}

.storage-map-marker{
	width: 30px;
	height: 30px;
	border-radius: 20px;
	border: 2px solid black;
	background: #fff;
	color: #000;
	cursor: pointer;
	transform: translate(-15px, -15px);
	display: flex;
        align-items: center;
        justify-content: center;
}

.purchase-map-marker-details {
    position: absolute;
    background: #ffffffb0;
    padding: 5px 10px;
    border-radius: 4px;
    top: -10px;
    left: 25px;
}

.purchase-map-corner-container {
    z-index: 10000;
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 5px 10px;
    background: #ffffffc4;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.purchase-map-filters {
    background: #ffffff9c;
    padding: 0px 20px 20px 20px;
    border-radius: 15px;
    display: flex;
    gap: 10px;
}

.purchase-map-links-container{
    height: calc(100vh - 215px);
    overflow-y: scroll;
}

.purchase-map-list, .purchase-map-links-container{
    height: calc(100vh - 215px);
    overflow-y: scroll;
}

.purchase-map-container{
    height: calc(100vh - 150px);
}

.purchase-map-active-marker{
    width: 350px;
    height: calc(100vh - 150px);
    z-index: 1;
    background: #ffffff;
    border-left: 1px dashed black;
    padding: 0px 20px;
    position: absolute;
    overflow-y: scroll;
}

.purchase-map-active-marker-close{
    position: absolute;
    top: 0;
    right: 10px;
    background: white;
    color: black;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
}
.purchase-map-route-counter {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -10px;
    right: -10px;
    font-size: 11px;
}

.purchase-map-route-counter.counter-black {
    background: #000;
    color: #fff;
}

.purchase-map-route-counter.counter-red {
    background: rgb(255, 0, 0);
    color: #fff;
}

.purchase-map-route-confirmed-badge {
    background: #459c0f;
    color: #fff;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
}

.purchase-map-route-counter-badge {
    background: rgb(255, 0, 0);
    color: #fff;
    border: 2px solid #fff;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -10px;
    right: -10px;
    font-size: 11px;
}

@keyframes pulse-black {
	0% {
		transform: transition(-15px, -15px) scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: transition(-15px, -15px) scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: transition(-15px, -15px) scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

@keyframes pulse-red {
	0% {
		transform: transition(-15px, -15px) scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
	}

	70% {
		transform: transition(-15px, -15px) scale(1);
		box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
	}

	100% {
		transform: transition(-15px, -15px) scale(0.95);
		box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
	}
}
